@import 'fonts.base64.scss';
@import "rx-separator";
@import "variables";
@import "denture-details-info.mixin";
@import "pontic.mixin";

body .landscape{
  margin-right: 0;
  margin-left: 45vw;
}

.test-container {
  border: 5px blue solid;
  width: 50px;
  height: 50px;
}

.print-container {
  background-color: white;
  color: #3e3d40;
  font-family: 'Avenir', sans-serif;
}

.title-text {
  margin-right: 12px;
  flex-grow: 0;
  font-family: 'Avenir', sans-serif;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3d40;
}

.title-prop-description {
  flex-grow: 0;
  font-family: 'Avenir', sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3d40;
}

.title-patient-fullname {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 100px;
}

.rx-container {
  margin: 25px 0px 42px 0px;
}

.rx-container:not(:last-child) {
  page-break-after: always;
}

textarea {
  page-break-inside: avoid;
}

.last_note {
  margin-bottom: 0;
}

.lowerSection {
  margin-left: -675px;
  padding: 0 10mm;
}

.detail-row {
  display: none;
}

.detail-row.show-scan-body-info {
  display: table-row;
}

.detail-row.show-nodata {
	display: table-row;
  }

.logo-container {
  position: relative;
  margin-left: auto;
}
.logo-container img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 82.1px;
  height: 33.6px;
}

.info-box {
  width: 100%;
  padding: 10px;
  row-gap: 10px;
}
.info-box-signature {
  row-gap: 0px;
}
.info-box.print-context-top {
  border-bottom: none;
}
.info-box.print-context-bottom {
  border-top: none;
}

.separator {
  margin: 0;
  border-left: 1px solid #cacaca;
  position: relative;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
}

.general-info-checkbox {
  margin: 22px auto 22px 32.2px;
}

.flex-body {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 1e-9px;
}

.rx-card-title {
  page-break-inside: avoid;
  background-color: #fff;
  padding-left: 16px;
  border: 1px solid #cacaca;
  border-bottom: none;
}

.rectangle-container {
  background-color: #fff;
  width: inherit;
  margin-bottom: 12px;
}
.rectangle-container div {
  background-color: #fff;
}
.rectangle-container .rx-card-title {
  border: 1px solid #cacaca;
  border-bottom: none;
}
.rectangle-container .flex-body {
  border: 1px solid #cacaca;
  flex-direction: row;
}

.general-info {
  font-family: 'Avenir', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.4px;
  text-align: left;
}

.rows-flex {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}

.general-info-footer {
  border-top: 1px solid #cacaca;
  height: 66px;
}

.column-primary {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 20%;
}

.teeth-diagram-container {
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 12px;
  font-size: 14px;
}

.teeth-diagram {
  min-height: 50px;
  background-color: #fff;
  padding: 5px 15px;
  font-size: 14px;
  border: 1px solid #cacaca;
}

.rx-card-title.print-context {
  background-color: #fff;
  border: 1px solid #cacaca;
  border-bottom: none;
}

.rx-card-title-print {
  border: 1px solid #cacaca;
  border-bottom: none;
  background-color: #fff;
  padding: 5px 20px;
  color: #3e3d40;
}

.general-info-body {
  border: 1px solid #cacaca;
}

.title-separator {
  width: 20px;
  margin: 0 10px;
  border-top: 2px solid #cacaca;
  transform: rotate(90deg);
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.jaw-tooth-container::ng-deep .mat-figure {
  display: flex;
  flex-direction: column;

}

.appear-read-only {
  opacity: 0.9;
}

.upper::ng-deep.mat-figure {
  justify-content: flex-end;
}

.lower::ng-deep.mat-figure {
  justify-content: flex-start;
}

.teeth-title,
.treatment-information-title,
.bridge-information-title,
.notes-title {
  color: #3e3d40;
  letter-spacing: 0.4px;
  size: 20px;
  font-family: 'Avenir', sans-serif;
  height: 42px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.7;
  text-align: left;
}

.jaw-teeth-container {
  display: flex;
  max-height: 50px;
  flex-direction: row;
  align-items: center;
}
.teeth-numbers-row {
  display: flex;
  justify-content: space-between;
  line-height: 1.8;
  align-items: baseline;
}

.teeth-numbers-row .tooth-number-container {
  width: 100%;
  text-align: center;
}
.jaw-tooth-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.jaw-tooth-container.upper {
  justify-content: flex-end;
}
.jaw-tooth-container.lower {
  justify-content: flex-start;
}

.tooth-image {
  max-width: 32px;
}

.landscape .tooth-image {
  max-width: 26px;
}

.bridge-indicator-container {
  display: flex;
  flex-direction: row;
}
.bridge-indicator__wrapper {
  width: 100%;
}

.bridge-indicator {
  height: 10px;
  display:block;
}

.bridge-indicator-upper {
  border-top: 2px solid $blue-print;

}
.bridge-indicator-lower {
  border-bottom: 2px solid $blue-print;
}

.rightmost-tooth-in-bridge {
  border-right: 2px solid $blue-print;
  margin-right: 5px;
}
.leftmost-tooth-in-bridge {
  border-left: 2px solid $blue-print;
  margin-left: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.rx-card-body {
  background-color: #fff;
  font-size: 18px;
  border: 1px solid #cacaca;
}

th {
  border-bottom: 1px solid #cacaca;
}

.field-name {
  color: #3e3d40;
  font-size: 14px;
  font-family: 'Avenir', sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

.field-name > span {
  font-weight: normal;
}

.field {
  position: relative;
}

.fields-container {
  height: 100%;
}

.signature {
  display: block;
  z-index: 1;
  max-width: 150px;
  height: 37px;
  object-fit: contain;
  margin-left: 10px;
}

.rx-container-landscape .signature {
  height: 60px;
}

.field-info {
  color: #3e3d40;
  font-size: 14px;
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  margin-left: 10px;
}

.rx-card-body.print-context {
  font-size: 14px;
}

th.mat-header-cell {
  /* stylelint-disable-next-line declaration-no-important */
  text-align: start !important;
}

td.mat-cell{
  padding: 8px 0px 10px 16px;
}

table.mat-table {
  width: 100%;
  background-color: #fff;
  table-layout: auto;
}
table.mat-table .invalid-for-send {
  color: red;
  font-weight: bold;
}
table.mat-table th.mat-header-cell {
  font-family: 'Avenir', sans-serif;
  font-weight: 900;
  font-size: 14px;
  line-height: 2;
  color: #3e3d40;
  padding: 0px 15px;
  overflow-wrap: break-word;
}
table.mat-table th.mat-header-cell div {
  font-family: 'Avenir', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.43; */
  letter-spacing: normal;
  text-align: left;
  color: var(--charcoal-grey);
  overflow-wrap: break-word;
}
table.mat-table td.mat-cell {
  font-family: 'Avenir', sans-serif;
  padding: 10px 15px;
  /* line-height: 1.28; */
  line-height: normal;
  word-break: break-word;
  font-size: 14px;
  font-weight: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border-top: 1px solid #cacaca;
}
table.mat-table .detail-row td.mat-cell {
  border-top: none;
}
.landscape table.mat-table td.mat-cell{
  padding: 5px 10px;
}
.landscape table.mat-table td.mat-cell,
.landscape table.mat-table th.mat-header-cell
{
  font-size: 11px;
}
.landscape table.mat-table th.mat-header-cell div{
  font-size: 10px;
}

.rx-card {
  margin-bottom: 12px;
}

.notes-title {
  background-color: #fff;
  border: 1px solid #cacaca;
  border-bottom: none;
}

.remove-note,
.edit-note {
  color: #408dc1;
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: inline-block;
  padding-top: 4px;
}

.remove-note mat-icon,
.edit-note mat-icon {
  width: 22px;
  height: 22px;
}

.edit-note {
  margin-right: 8px;
}

.date-and-time {
  color: #3e3d40;
  letter-spacing: 0.36px;
  line-height: 1;
  opacity: 0.6;
  margin-left: 12px;
  padding-top: 2px;
}

.date-and-time .line-separator {
  display: inline-block;
  margin: 0 10px;
}

.user-indicator {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  color: white;
  font-size: 24px;
  cursor: default;
}

.note-row {
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'Avenir', sans-serif;
  font-size: 20px;
  break-inside: avoid;
}

.note-content textarea {
  border: none;
  width: 100%;
  font-size: 14px;
  font-family: 'Avenir', sans-serif;
  font-style: normal;
  padding-right: 10px;
  float: left;
  overflow: hidden;
  resize: none;
}

.note-title {
  font-weight: 400;
  font-size: 16px;
}

.note-title-position {
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
}

.note-title-position-print {
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  flex-direction: row;
  align-items: center;
  line-height: 2;
  padding-bottom: 5px;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
}

textarea.notes-input {
  box-sizing: content-box;
  /* stylelint-disable-next-line declaration-no-important */
  overflow: hidden !important;
}

textarea.hidden{
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

.print-notes-input {
    padding-left: 10px;
    word-break: break-word;
    white-space:pre-line;
    margin-top:0px;
    font-weight: 400;
    font-size: 16px;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #419bf9;
}

mat-form-field {
  padding: 0;
}

mat-form-field.disabled {
  pointer-events: none;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
  padding-top: 10px;
  padding-left: 10px;
}

::ng-deep .read-only .notes-card-body textarea.mat-input-element {
  opacity: 1;
  line-height: 30px;
  page-break-inside: avoid;
  page-break-after: auto;
}

::ng-deep .mat-form-field-appearance-standard .mat-form-field-underline {
  height: 0;
}

.notes-card-body {
  padding-top: 15px;
  min-height: 0;
  page-break-inside: auto;
}

.not-editable {
  opacity: 1;
}

.tooth-numbers-separator {
  border: 0;
  border-top: 2px solid #cacaca;
}

.header-position {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
}

.selected-bridge {
  font-size: 14px;
  text-align: center;
  /* stylelint-disable-next-line declaration-no-important */
  width: fit-content !important;
  /* stylelint-disable-next-line declaration-no-important */
  min-width: 16px !important;
  border-radius: 50%;
  margin: 2px auto 0px auto;
  border: solid 2px $blue-print;
  line-height: 1.28;
  -webkit-print-color-adjust: exact;
}

.bridges-container {
  margin-top: 12px;
}

.pontic-size {
  display: block;
  max-width: 48%;
}

.mat-radio-container {
  display: none;
}

.mat-radio-label-content {
  display: flex;
  flex-direction: column-reverse;
}

.custom-selector{
  width: 23px;
  height: 23px;
  visibility: hidden;
}

.mat-radio-checked .custom-selector {
  visibility: visible;
}

.treatment-info-details {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
.separator.treatment-info {
    margin-left: 16px;
    margin-right: 16px;
    height: 11px;
}
.separator-subsection{
    margin-left: 5px;
    margin-right: 5px;
    height: 15px;
    border-left: 1px solid #cacaca;
    position: relative;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    transform: rotate(20deg)
}
.container {
  display: flex;
  flex-wrap: wrap;
}
.detail-header {
    font-weight: 800;
    font-size: 14px;
    margin-right: 6px;
}
.landscape .detail-header {
  font-size: 11px;
}
.detail-container{
  display: inline-flex;
}

.border-box {
  box-sizing: border-box;
}

.mould-element {
  width: inherit;
  height: inherit;

  &__image {
    width: inherit;
    height: inherit;
  }
}
.scan-options{
  display: flex;
  flex-direction: column;

  .rx-card-body {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 10px;

	.mat-radio-label-content{
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  gap: 12px;
	}

	.two-columns{
		flex: 0 1 50%;
	}

	.three-columns{
		flex: 0 1 33%;
	}
  }
	.additional-bite {
		margin-left: 0px;
		margin-right: auto;
		align-items: center;
	}

	.additional-bites-row {
		font-family: $font-family-avenir;
		margin-left: 18px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
	}

	.general-info-checkbox-print{
		width: 15px;
		height: 15px;
	}

	.resto-multi-bite-title{
		font-size: 14px;
	}
}


@include denture-details-info($isPrintContext: true);
@include pontic;
